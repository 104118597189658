body {
  padding: 4rem 0;
  text-align: center;
}

button {
  padding: .8rem 2rem;
  font-weight: 600;
  font-size: 1.25rem;
  color: #7952b3;
  border-color: #7952b3;
  margin-bottom: 1rem;
  line-height: 1.5;
  border-radius: .3rem;
}

p {
  font-size: 1.25rem;
  font-weight: 300;
  padding-bottom: 1rem;
}

.feedback-success {
  color: #155724;
}

.feedback-error {
  color: #721c24;
}

label {
  display: block;
  margin-bottom: 1rem;
}
